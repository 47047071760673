import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Footer from './../components/footer'
import { bdstatistics, apiURL, mqStatistics } from '../components/census'

const Coming = () => {

  React.useEffect(() => {
    bdstatistics()
    mqStatistics()
  }, [])
  
  return (
    <div className="prices-page">
      <div className="home-bg" style={{ background:`url(${require("./../images/coming-soon.png")}) no-repeat`, backgroundSize: '100% 100%', maxHeight: '44.375rem' }}>
        <Layout>
          <SEO title="物联网平台_数采平台_工业物联网 ——低代码搭建IOT平台" />
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '38.125rem' }}>
            <h1 style={{
              marginTop: '17.75rem', color: '#373E98', fontWeight: 400, fontSize: '1.25rem', 
              fontFamily: 'PingFangSC-Regular, PingFang SC', textAlign: 'center' }}>开发中，敬请期待~</h1>
          </div>
        </Layout>
      </div>
      <Footer></Footer>
    </div>
  )
}

export default Coming
